import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image'

import ButtonBordered from '../button-bordered/button-bordered'


const BookCta = ({marginBottom, bg, link, phone, email, discount}) => (
  <div className={`${marginBottom && 'mb-10 md:mb-16'} flex flex-wrap`}>
    <BackgroundImage
      Tag="section"
      fluid={bg}
      className="w-full md:w-1/2 flex-auto"
    >
      <div className="mx-auto w-full py-16 md:py-24 px-4 md:px-4 text-center">
        <div className="relative text-white z-10">
          <h3 className="uppercase text-4xl mb-3">Book your ely salon appointment today</h3>
          <p className="leading-loose mb-6 relative max-w-3xl mx-auto">
            You can book your ely appointments by calling <b>{phone}</b> or direct with our online booking system. 
            Please feel free to message us on <b>Facebook</b> or by email at <b>{email}.</b>
          </p>
          <ButtonBordered windowed text='Book Online' link={link ? link : 'https://phorest.com/book/salons/elybeautyandskinclinic'} />
        </div>
      </div>
      <div className="inset-0 absolute bg-primary-darker opacity-75"/>
    </BackgroundImage>
    <div className="w-full md:w-1/2 flex-auto relative">
      <div className="max-w-6xl mx-auto w-full py-16 md:py-24 px-4 md:px-4 text-center">
        <div className="relative text-white z-10">
          <h3 className="uppercase text-5xl mb-5">Purchase a <br/>gift voucher</h3>
          <ButtonBordered windowed text="Purchase gift voucher" link={discount} />
        </div>
      </div>
    <div className="inset-0 absolute bg-primary"></div>
    </div>
  </div>
);

BookCta.propTypes = {
  data: PropTypes.object,
  marginBottom: PropTypes.bool,
  bg: PropTypes.object,
  phone: PropTypes.string,
  email: PropTypes.string,
  link: PropTypes.string,
  discount: PropTypes.string
}

export default BookCta
