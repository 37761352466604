import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import SmallHero from '../components/elements/small-hero/small-hero';
import BookCta from '../components/elements/book-cta/book-cta';
import SubIntro from '../components/elements/sub-intro/sub-intro';

const CancellationPage = ({ data }) => {

  let [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Layout>
      <SEO
        title="Cancellation/Refund Policy"
      />
      <SmallHero 
        marginBottom
        loaded={loaded}
        bgImage={data.herofull.childImageSharp.fluid}
        title='Cancellation/Refund Policy'
      />
      <SubIntro 
        text={<div className="default-text text-left">
        <h2><strong>Cancellation Policy</strong></h2>
          <p>
          Please be advised that should you wish to cancel your appointment with House of
          Pure 48 hours notice will be needed.
          <br/> <br/>
          If you are unable to give 48 hours notice the appointment becomes payable in full at
          the time of cancellation.
          <br/> <br/>
          If you have booked with a voucher, the appointment amount will be deducted from
          the relevant voucher.
          <br/> <br/>
          No further appointments can be booked until the outstanding amount is paid.
          <br/> <br/>
          We thank you for your understanding and co-operation with this matter and look
          forward to welcoming you to Ely Beauty and Skin Clinc in the near future.
          </p>
          <h2><strong>Refund Policy</strong></h2>
          <p>
            Refunds will be made against products that are unused and in original packaging with 7 days of purchase with proof of purchase.  If you are unhappy with a service please contact the salon within 2 days to discuss.
          </p>
        </div>
        }
      />
      <BookCta discount="https://gift-cards.phorest.com/salons/elybeautyandskinclinic" link="https://phorest.com/book/salons/elybeautyandskinclinic" phone="01353 661 321" email="elybeautyandskinclinic@gmail.com" bg={data.bookbg.childImageSharp.fluid} />
    </Layout>
  );
}

CancellationPage.propTypes = {
  data: PropTypes.object,
};

const indexQuery = graphql`
  query {
    herofull: file(relativePath: { eq: "main-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const CancellationComponent = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <CancellationPage props data={data} {...props} />
    )}
  />
)

CancellationComponent.displayName = "CancellationComponent"

export default CancellationComponent
