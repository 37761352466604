import React, { useEffect, useState } from "react";
import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import SubHero from '../components/elements/sub-hero/sub-hero';
import TwoColFullScreen from '../components/layouts/2-col-full-screen/2-col-full-screen';
import ServiceInfo from '../components/services/service-info/service-info';
import BookCta from '../components/elements/book-cta/book-cta';
import Testimonials from '../components/elements/testimonials/testimonials';
import InstaFeed from '../components/elements/insta-feed/insta-feed';

const HairPage = ({ data }) => {

  let [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Layout pageType='hair'>
      <SEO
        keywords={[`Hair Ely`, `Hair Salon Ely`, `Hair Treatments Ely`, 'hairdressers ely']}
        title="Ely Beauty and Skin Clinc Hair is a luxury Hairdressers Salon in Ely"
        description="We're a new luxury hair salon & hairdressers based in the heart of Ely, our salon offers a range of cut and colour packages as well as hair styling special occasions, including bridal packages. We also are able to offer keratin-smoothing treatments, to eliminate frizz and create smooth, shiny hair."
      />
      <SubHero 
        loaded={loaded}
        bgImage={data.herofull.childImageSharp.fluid}
        data={{
          title: 'Luxury Hairdressers in the heart of Ely',
          subTitle: 'Our hair salon offers a wide range of bespoke cutting & colouring services from our talented team as well as keratin smoothing & occasion hair. We are L’Oreal professional and Kerastase salon, drop into our beautiful hairdressers salon for a free consultation or register to book Online.',
        }}
      />
      {loaded && <div>
      <TwoColFullScreen cols={[{
          bg: 'text-white bg-primary-darker',
          content: <div>
            <ServiceInfo 
              key={1}
              title="CUTTING"
              priceList={[
                {
                  price: '£55',
                  text: 'Senior Stylist',
                  padding: 'pr-6'
                },
                {
                  price: '£45',
                  text: 'Stylist',
                }
              ]}
              smallPrice
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-2xl -mb-8 font-bold mt-6">Ladies Cut & Finish</h3>
                </div>
              }
            />
            <ServiceInfo 
              key={2}
              smallPrice
              priceList={[
                {
                  price: '£35',
                  text: 'Senior Stylist',
                  padding: 'pr-6'
                },
                {
                  price: '£30',
                  text: 'Stylist',
                }
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-2xl -mb-8 font-bold">Mens Cut & Finish</h3>
                </div>
              }
            />
            <ServiceInfo 
              key={2}
              smallPrice
              priceList={[
                {
                  price: '£65',
                  text: 'Senior Stylist',
                  padding: 'pr-6'
                },
                {
                  price: '£55',
                  text: 'Stylist',
                }
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-2xl -mb-8 font-bold">Complete Restyle</h3>
                </div>
              }
            />
            <ServiceInfo 
              key={2}
              smallPrice
              priceList={[
                {
                  price: '£15',
                  text: '12 and under',
                }
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-2xl -mb-8 font-bold">Childs Dry Hair Cut</h3>
                </div>
              }
            />
            <ServiceInfo 
              key={8}
              smallPrice
              priceList={[
                {
                  price: '£30',
                  text: '12 and under',
                }
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-2xl -mb-8 font-bold">Childs Wash, Cut & Blowdry</h3>
                </div>
              }
            />
            </div>
        },
        {
          bg: 'bg-white',
          content: <>
            <ServiceInfo 
              key={2}
              title="STYLING"
              priceList={[
                {
                  price: '£35',
                  text: 'Wash & Blow Dry',
                  padding: 'mb-4'
                },
                {
                  price: '£38',
                  text: 'Hair Extension Blow Dry - Includes cleanse, condition, blow dry and finish.',
                  padding: 'mb-4'
                },
                {
                    price: 'From £55',
                    text: 'Occasion Hair Up',
                    padding: 'mb-4'
                },
                {
                price: '£26',
                    text: 'Curl and GO * no wash, instant curls for any event',
                    padding: 'mb-4'
                },   
                {
                  price: 'Wedding Hair',
                  text: 'P.O.A - In salon, or at wedding venue, will require consultation.',
                }
              ]}
              alignPrice="bottom"
              row        
              lowerText={<div>
                <p className="text-primary -mt-5">
                 <Link to="/hair/wedding-hair/">See wedding packages</Link>
                </p>
              </div>
            }  
            />          
          </>
        },
        {
          bg: 'bg-primary-darker text-white md:text-black md:bg-white',
          content: <div>
              <ServiceInfo 
              key={3}
              title="COLOUR"
              alignPrice="bottom"
              upperText="All our colour appointments include either a toner or gloss- this will be decided by your stylist at time of application."
              row
            />
             <ServiceInfo 
              key={0}
              smallPrice
              priceList={[
                {
                  price: '£65',
                  text: 'Senior Stylist/Colourist',
                  padding: 'pr-6'
                },
                {
                  price: '£40',
                  text: 'Stylist',
                },
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-3xl text-primary font-extrabold mt-6">Face Framing highlights </h3>
                </div>
              }
            />
            <ServiceInfo 
              key={1}
              smallPrice
              priceList={[
                {
                  price: '£75',
                  text: 'Senior Stylist/Colourist',
                  padding: 'pr-6'
                },
                {
                  price: '£55',
                  text: 'Stylist',
                },
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-3xl text-primary font-extrabold mt-6">T section highlight</h3>
                  <p className="-mb-4">
                  *Placement of highlights along the parting and hair line.
                  </p>
                </div>
              }
            />
            <ServiceInfo 
              smallPrice
              key={1}
              priceList={[
                {
                  price: '£90',
                  text: 'Senior Stylist/Colourist',
                  padding: 'pr-6'
                },
                {
                  price: '£78',
                  text: 'Stylist',
                }
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-3xl -mb-6 text-primary font-extrabold">½ head highlights</h3>
                </div>
              }
            />
            <ServiceInfo 
              smallPrice
              key={1}
              priceList={[
                {
                  price: '£102',
                  text: 'Senior Stylist/Colourist',
                  padding: 'pr-6'
                },
                {
                  price: '£90',
                  text: 'Stylist',
                },
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-3xl -mb-6 text-primary font-extrabold">Full head highlights</h3>
                </div>
              }
            />
            <ServiceInfo 
              smallPrice
              key={1}
              priceList={[
                {
                  price: 'From £82',
                  text: 'Senior Stylist/Colourist',
                  padding: 'pr-6'
                },
                {
                  price: 'From £70',
                  text: 'Stylist',
                }
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-3xl text-primary font-extrabold">Combination colour</h3>
                  <p className="-mb-4">
                  (Foils and colour)
                  </p>
                </div>
              }
            />
            <ServiceInfo 
              smallPrice
              key={1}
              priceList={[
                {
                  price: '£115',
                  text: 'Senior Stylist/Colourist',
                  padding: 'pr-6'
                },
                {
                  price: '£92',
                  text: 'Stylist',
                },
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-3xl text-primary font-extrabold -mb-4">Balayage</h3>
                </div>
              }
            />
            <ServiceInfo 
                key={18}
                smallPrice
                priceList={[
                  {
                    price: '£70',
                    text: 'Senior Stylist/Colourist',
                  },
                  {
                    price: '£60',
                    text: 'Stylist',
                  },
                ]}
                alignPrice="bottom"
                upperText={<div>
                    <h3 className="text-3xl text-primary font-extrabold -mb-4">Full Head Colour/Tint</h3>
                  </div>
                }
              />  
            <ServiceInfo 
              key={1}
              priceList={[
                {
                    price: '£55',
                    text: 'Senior Stylist/Colourist',
                },
                {
                price: '£46',
                text: 'Stylist',
                },
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-3xl text-primary font-extrabold -mb-5">Root Colour</h3>
                </div>
              }
            />
            <ServiceInfo 
              key={45}
              priceList={[
                {
                    price: '£100',
                    text: 'Senior Stylist/Colourist',
                },
                {
                price: '£89',
                text: 'Stylist',
                },
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-3xl text-primary font-extrabold -mb-5">Scalp Bleach</h3>
                </div>
              }
            />
            <ServiceInfo 
              key={45}
              priceList={[
                {
                    price: '£65',
                    text: 'Senior Stylist/Colourist',
                },
                {
                price: '£55',
                text: 'Stylist',
                },
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-3xl text-primary font-extrabold -mb-5">Scalp Bleach (roots only)</h3>
                </div>
              }
            />
            <ServiceInfo 
              smallPrice
              key={71}
              priceList={[
                {
                    price: 'starting from £85',
                }
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-3xl text-primary font-extrabold">Creative Colour</h3>
                  <p>
                  Creative colour appointments will require a consultation with our colourist.
                  </p>
                  <p className="text-xs -mb-4">**Please note that for some creative colour apppintments your hair may need to be bleached prior to colour application</p>
                </div>
              }
            />
            <ServiceInfo 
              smallPrice
              key={1}

              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-3xl text-primary font-extrabold">Colour Correction - P.O.A</h3>
                  <p className="-mb-4">
                  *Colour correction - Light to dark, dark to light or any major corrective colour work.
                  </p>
                </div>
              }
            />
             <ServiceInfo 
              smallPrice
              key={81}

              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-lg text-primary font-extrabold mb-4">*Thick/Long hair supplement for all colour & styling appointments to allow extra time and product - £20</h3>
                  <p>All new colour clients will require a skin test 48 hours prior to appointment.</p>
                </div>
              }
            />
          </div>
        },
        {
          bg: 'bg-white md:text-white md:bg-primary-darker',
          content: <div>
            <ServiceInfo 
              key={561}
              title="Junior Stylist Prices"
              priceList={[
                {
                    price: '£18',
                    text: 'Blow Dry',
                },
                {
                    price: '£13',
                    text: 'Curl & Go',
                },
                {
                    price: '£26',
                    text: 'Cut and blow dry',
                },
                {
                    price: '£40',
                    text: 'T Section Highlights',
                },
                {
                    price: '£45',
                    text: '½ Head Highlights',
                },
                {
                    price: '£52',
                    text: 'Full Head Highlights',
                },
                {
                    price: '£32',
                    text: 'All Over Colour (Permanent or Semi-Permanent)',
                },
              ]}
              alignPrice="bottom"
              lowerText={<div>
                  <p className="text-sm mb-10">*All our trainee treatments are carried out in a professional manner and over seen by a senior stylist.</p>
                </div>
              }
            />
            <ServiceInfo 
              key={4} 
              title="KERATIN SMOOTHING"
              priceList={[
                {
                  text: 'Fine hair',
                  price: '£190',
                },
                {
                  text: 'Medium hair',
                  price: '£220',
                },
                {
                  text: 'Thick hair',
                  price: '£240',
                }              
              ]}
              alignPrice="center"
              upperText={<p className="mb-10">Designed to eliminate frizz, create shine and smoothness to even the unruliest hair. All treatment prices include an aftercare package with high quality products. 
              Please call or drop by for a consultation. 
              <br/>*£50 deposit required on date of booking.</p>}
            />
            <ServiceInfo 
              key={5}
              title="Additional Services"
              priceList={[
                {
                    price: '£15',
                    text: 'FusioDose Add on	Treatment',
                    padding: 'mb-4'
                },
                {
                    price: '£46',
                    text: 'Kerastase FusioDose & Blowdry',
                    padding: 'mb-4'
                },
                {
                    price: '£22',
                    text: 'Absolut Repair Molecular Add on',
                    padding: 'mb-4'
                },
                {
                    price: '£55',
                    text: 'Absolut Repair Molecular & Blowdry',
                    padding: 'mb-4'
                },
                {
                  price: 'Starting from £45',
                  text: 'Toner Refresh (Includes blow dry)',
                  padding: 'mb-4'
                },
              ]}
              alignPrice="bottom"
              smallPrice
            />
             <ServiceInfo 
              key={15}
              title="Prom Packages"
              upperText={<p className="-mt-5 mb-10">Please contact us if you are looking to book prom hair and make up packages. 
              </p>}
          
            />
          </div>
        }
      ]}/>
      <Testimonials />
      <BookCta discount="https://gift-cards.phorest.com/salons/elybeautyandskinclinic" link="https://phorest.com/book/salons/elybeautyandskinclinic" phone="01353 661 321" email="elybeautyandskinclinic@gmail.com" bg={data.bookbg.childImageSharp.fluid} marginBottom />
      <InstaFeed token="IGQVJWSFBsWDctOXpzeVo4S1VNYjJOc0hHbnY4OHR4ZAUZAPc193c3pJRGY1M2NNVS16RnpRaHpJeEF3Yy1HRjN4OVQ2QndlZAzdhWFlsYkhoYTVOcmZA4cTYyMzFNOVBtdnBmX0VEbE5yVHFabkNka1VDdAZDZD" />
      </div>}
    </Layout>
  );
}

HairPage.propTypes = {
  data: PropTypes.object,
};

const indexQuery = graphql`
  query {
    herofull: file(relativePath: { eq: "hair-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bookbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const HairComponent = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <HairPage props data={data} {...props} />
    )}
  />
)

HairComponent.displayName = "HairComponent"

export default HairComponent