import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import SmallHero from '../components/elements/small-hero/small-hero';
import BookCta from '../components/elements/book-cta/book-cta';

const TrustedPartnersPage = ({ data }) => {

  let [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Layout>
      <SEO
        title="Trusted Partners"
      />
      <SmallHero 
        marginBottom
        loaded={loaded}
        bgImage={data.herofull.childImageSharp.fluid}
        title='Trusted Partners'
      />
      <div className="max-w-6xl mx-auto flex flex-wrap mb-20 md:mb-32">
        <div className="w-full md:w-1/2 mb-10">
          <div className="px-5">
            <a href="https://www.eelcatchersdaughter.co.uk" rel="noreferrer" target="_blank" className="block mb-2 text-3xl hover:text-primary">THE EEL CATCHERS DAUGHTER</a>
            <p className=" text-xl mb-4">The perfect place to browse and get that much wanted gift or just a treat for yourself!</p>
            <p className="text-sm">Do you like discovering that gem of a pub, collecting treasures from a forest floor or windswept beach, hunting out curiosities in a flea market? Simple pleasures and finding joy in simple things .. let us appeal to your senses ...
            Imagine a place to discover new favourites, a treasure trove of tempting things waiting to be found, everything lovingly wrapped by smiling, friendly faces with a genuine passion.  Imagine all this, housed in the ancient walls of a glorious cathedral in a tiny fenland city ..
            You&apos;ve opened the door to The Eel Catcher&apos;s Daughter</p>
          </div>
        </div>
        <div className="w-full md:w-1/2 mb-10">
          <div className="px-5">
            <a href="https://wildcloverflowercompany.co.uk" rel="noreferrer" target="_blank" className="block mb-2 text-3xl hover:text-primary">WILD CLOVER FLOWER COMPANY</a>
            <p className=" text-xl mb-4">BEAUTIFUL BESPOKE FLORIST ON OUR DOORSTEP.  </p>
            <p className="text-sm">“Over the years I have been given the great privilege to plan and create floral designs to mark important events in so many people’s lives including Courtship, Weddings, Births, Christenings and the loss of loved ones to convey an expression of feelings.
            I have also produced floral installations and displays for all different types of venues, hotels and corporate businesses for conferences, product launches and new store openings”</p>
          </div>
        </div>
        <div className="w-full md:w-1/2 mb-10">
          <div className="px-5">
            <a href="https://www.elygrazing.com" rel="noreferrer" target="_blank" className="block mb-2 text-3xl hover:text-primary">Ely Grazing</a>
            <p className=" text-xl mb-4">Opened summer 2022 with a massive bang in the Buttermarket. </p>
            <p className="text-sm"> A fabulous addition that compliments all the businesses in the Buttermarket. Gorgeous cocktails and food from Martin and his team, with the added extra of Ely Gin and a shop full of goodies to be had!</p>
          </div>
        </div>
        <div className="w-full md:w-1/2 mb-10">
          <div className="px-5">
            <a href="https://www.poetshouse.co.uk" rel="noreferrer" target="_blank" className="block mb-2 text-3xl hover:text-primary">Poets House</a>
            <p className=" text-xl mb-4">Stay, Wine, Dine or Wedding it’s the perfect location for all!</p>
            <p className=" text-sm mb-4">We have many Brides who are staying or getting Married here. We can attend to do hair and makeup or our Salon is a short walk away.</p>
            <p className="text-sm"> A beautiful location right in the centre of Ely.  We have had many an Afternoon Tea at Poets!  The relaxed atmosphere and attention to detail is first class!</p>
          </div>
        </div>
      </div>
      <BookCta discount="https://gift-cards.phorest.com/salons/elybeautyandskinclinic" link="https://phorest.com/book/salons/elybeautyandskinclinic" phone="01353 661 321" email="elybeautyandskinclinic@gmail.com" bg={data.bookbg.childImageSharp.fluid} />
    </Layout>
  );
}

TrustedPartnersPage.propTypes = {
  data: PropTypes.object,
};

const indexQuery = graphql`
  query {
    herofull: file(relativePath: { eq: "main-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const TrustedPartnersComponent = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <TrustedPartnersPage props data={data} {...props} />
    )}
  />
)

TrustedPartnersComponent.displayName = "TrustedPartnersComponent"

export default TrustedPartnersComponent
