import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types";


import Layout from "../components/layout";
import SEO from "../components/seo";

import SmallHero from '../components/elements/small-hero/small-hero';
import BookCta from '../components/elements/book-cta/book-cta';

const ContactPage = ({ data }) => {

  let [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Layout>
      <SEO
        keywords={[`Training`]}
        title="Training"
      />
      <SmallHero 
        marginBottom
        loaded={loaded}
        bgImage={data.herofull.childImageSharp.fluid}
        title='Training'
      />
      <div className="flex flex-wrap mx-auto max-w-screen-xl mb-10 md:mb-32 justify-between">
        <h3 className="text-primary text-4xl uppercase font-light leading-tight mb-4 px-4">GET IN TOUCH</h3>
        <p className="font-light leading-tight mb-4 px-4">Our training site is under construction but in the meantime to hear about latest courses or to be sent our latest training prospectus please leave your details below</p>
        <form name="contact" method="POST" action="https://formspree.io/f/xvonylyb" className="flex flex-wrap text-white">
          <div className="w-full md:w-1/2 px-4 mb-4">
            <input type="text" name="name"  required className="w-full bg-primary placeholder-opacity-75 placeholder-white p-4" placeholder="name" />
          </div>
          <div className="w-full md:w-1/2 px-4 mb-4">
            <input type="email" name="email" required className="w-full bg-primary placeholder-opacity-75 placeholder-white p-4" placeholder="email" />
          </div>
          <div className="w-full px-4">
            <button type="submit" className="bg-primary-dark text-white py-4 px-8 hover:bg-primary-darker duration-300">Submit</button>
          </div>
        </form>
      </div>
      <BookCta discount="https://gift-cards.phorest.com/salons/elybeautyandskinclinic" link="https://phorest.com/book/salons/elybeautyandskinclinic"  phone="01353 661 321" email="elybeautyandskinclinic@gmail.com" bg={data.bookbg.childImageSharp.fluid} />
    </Layout>
  );
}

ContactPage.propTypes = {
  data: PropTypes.object,
};

const indexQuery = graphql`
  query {
    herofull: file(relativePath: { eq: "main-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const ContactComponent = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactPage props data={data} {...props} />
    )}
  />
)

ContactComponent.displayName = "ContactComponent"

export default ContactComponent