import React from "react";
import PropTypes from "prop-types";



import './socials.style.scss';

const Socials = () => {

  return (
    <div className="flex flex-wrap">
       {/* <a rel="noreferrer" target="_blank" className="social-link" href="https://twitter.com/pure_ely">
          <img width="19" height="15" alt="twitter" src={twitterIcon} />
        </a>
        <div className="px-8">
        <a rel="noreferrer" target="_blank" className="social-link" href="https://www.instagram.com/house__of__pure/">
          <img width="18" height="18" alt="instagram" src={instaIcon} />
        </a>
        </div>
        <a rel="noreferrer" target="_blank" className="social-link" href="https://www.facebook.com/www.houseofpure.co.uk">
          <img width="8" height="16" alt="facebook" src={facebookIcon} />
        </a> */}
    </div>
  );
}

Socials.propTypes = {
  dark: PropTypes.bool,
  primary: PropTypes.bool
};

export default Socials;
