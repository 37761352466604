import React, {useState, useEffect, useRef} from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types";
import throttle from 'underscore/modules/throttle.js'

import gsap, {Power3} from 'gsap';

import ButtonBordered from './elements/button-bordered/button-bordered';
import Socials from './elements/socials/socials';
import MainNav from './elements/mainnav/mainnav';
import MenuToggleBtn from './elements/mainnav/menu-toggle-btn/menu-toggle-btn';

import mainLogo from "../images/ely-beauty-and-skin-clinic.png";

import mobileLogo from "../images/white-circle-free-png.webp";

import './header.style.scss'

const Header = ({loaded, logoType}) => {
  let header = useRef(null);
  let logoImage ,bookingLink,openingTimes
  switch (logoType) {
    case 'beauty':
      logoImage = mainLogo
      bookingLink = 'https://phorest.com/book/salons/elybeautyandskinclinic'
      openingTimes = <div className="header--hidden text-white text-xs mt-4">
        <b>Mon:</b> 9am to 6pm <br/>
        <b>Tue:</b> 9am to 8pm <br/>
        <b>Wed:</b> 9am to 6pm <br/>
        <b>Thu:</b> 9am to 5pm <br/>
        <b>Fri:</b> 9am to 5pm <br/>
        <b>Sat:</b> 9am to 3pm <br/>
      </div>
      break;
    case 'aesthetics':
      logoImage = mainLogo
      bookingLink = 'https://phorest.com/book/salons/elybeautyandskinclinic'
      openingTimes = <div className="header--hidden text-white text-xs mt-4">
        <b>Mon:</b> 9am – 5pm <br/>
        <b>Tue:</b> 9am – 8pm <br/>
        <b>Tue:</b> 9am – 6pm <br/>
        <b>Thu - Fri:</b> 9am – 5pm <br/>
        <b>Sat:</b> 9am – 3pm <br/>
      </div>
      break;
    case 'academy':
      logoImage = mainLogo
      openingTimes = null
      break;
    case 'hair':
      logoImage = mainLogo
      bookingLink = 'https://phorest.com/book/salons/elybeautyandskinclinic'
      openingTimes = <div className="header--hidden text-white text-xs mt-4">
        <b>Mon:</b> 9am to 6pm <br/>
        <b>Tue:</b> 9am to 8pm <br/>
        <b>Wed:</b> 9am to 6pm <br/>
        <b>Thu:</b> 9am to 5pm <br/>
        <b>Fri:</b> 9am to 5pm <br/>
        <b>Sat:</b> 9am to 3pm <br/>
      </div>
      break;
    default:
      logoImage = mainLogo
      bookingLink = 'https://phorest.com/book/salons/elybeautyandskinclinic'
      openingTimes = null
      break;
  }
  const logo = useRef(null);
  const bookBtn = useRef(null);
  const socials = useRef(null);

  const [toggled, setToggled] = useState(false)
  const handleToggleMenu = () => {
    setToggled((toggle) => !toggle);
  }

  useEffect(() => {
    const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if(loaded) {  
      if(width > 767) {
        gsap.fromTo(socials.current, {y: -15, opacity: 0}, {y: 0, opacity: 1, duration: 1, delay: .3, ease: Power3.easeInOut})
        gsap.fromTo(logo.current, {y: -15, opacity: 0}, {y: 0, opacity: 1, duration: 1, delay: .5, ease: Power3.easeInOut}, 'logo')
        gsap.fromTo(bookBtn.current, {y: -15, opacity: 0}, {y: 0, opacity: 1, duration: 1, delay: .7, ease: Power3.easeInOut})
      }
    }
  }, [loaded]);

  useEffect(() => {
    const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if(width > 767) {
      const headerScroll = throttle(() => {
        // Detect scroll position
        let scrollPosition = Math.round(window.scrollY);

        // If we've scrolled 100px, add "sticky" class to the header
        if (scrollPosition > 100 && scrollPosition < 350){
          document.querySelector('.header').classList.add('header--animate-up')
        } 
        else if (scrollPosition > 349){
          document.querySelector('.header').classList.add('header--scrolled')
          setTimeout(() => {
            document.querySelector('.header').classList.remove('header--animate-up')
          }, 300)
        } 
        // If not, remove "sticky" class from header
        else {
          document.querySelector('.header').classList.remove('header--scrolled')
          document.querySelector('.header').classList.remove('header--animate-up')
        }
      
      }, 20)

      if (typeof window !== `undefined` || typeof document !== `undefined`) {
        window.addEventListener('scroll', headerScroll);
        document.addEventListener('DOMContentLoaded', headerScroll);
      }
    } else {
      const headerScroll = throttle(() => {
        // Detect scroll position
        let scrollPosition = Math.round(window.scrollY);

        // If we've scrolled 100px, add "sticky" class to the header
        if (scrollPosition > 100){
          document.querySelector('.header').classList.add('header--scrolled')
        } 
        // If not, remove "sticky" class from header
        else {
          document.querySelector('.header').classList.remove('header--scrolled')
        }
      
      }, 20)

      if (typeof window !== `undefined` || typeof document !== `undefined`) {
        window.addEventListener('scroll', headerScroll);
        document.addEventListener('DOMContentLoaded', headerScroll);
      }
    }

  }, []);


  return (
    <header ref={header} className="header z-50 fixed w-full">
      <div className="max-w-screen-xl py-2 px-2 sm:p-4 mx-auto md:p-4 w-full">
        <div className="header__wrapper flex flex-wrap justify-between items-center">
          <div ref={socials} className="header__side hidden md:block w-1/4 md:mt-12">
            <Socials />
            {openingTimes && openingTimes}
          </div>
          <div className="header__logowrapper flex flex-wrap flex-col items-center md:mb-10">
            <AniLink
              paintDrip   
              duration={1.2}
              hex="#696d49"
              bg="#696d49"className="inline-block" to="/"
            >
              <img width="200" ref={logo} className="header__logo--full hidden md:block" alt="logo" src={logoImage} />
              <img height="58" width="64" className="header__logo--icon w-16 md:hidden" alt="logo" src={mobileLogo} />
            </AniLink>
          </div>
          <div ref={bookBtn} className={`header__side hidden md:block w-1/4 text-right md:mt-12 md:pt-3`}>
              <ButtonBordered windowed text="Book Online" link={bookingLink} />
          </div>
          <MenuToggleBtn toggled={toggled} toggleMenu={handleToggleMenu} />
        </div>
        <div className="header__nav flex flex-wrap justify-center">
          <MainNav loaded={loaded} toggled={toggled}/>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  loaded: PropTypes.bool,
  logoType: PropTypes.string,
};

export default Header;
